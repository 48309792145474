<template>
  <b-row> </b-row>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt';
export default {
  components: {},

  mounted() {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
    localStorage.removeItem('userData');
    this.$router.push({ name: 'auth-login' });
  },
};
</script>
